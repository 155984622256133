import React, { Component } from "react";
import "../../assets/css/DonationComponent.css";
import "../../assets/css/responsive.css";
import Spinner from "../../Spinner";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "../GrapeJuiceNavbar"
import Footer from "../Footer";
import RegistrationForm from "./RegistrationForm";
import BlueSection from "../BlueSection";
import { ReactComponent as BackgroundSVG } from "../../assets/img/background2.svg";
import axios from "axios";

class RegistrationComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      currentURL: "",
      data: [],
    };
  }

  updateIsLoading = (state) => {
    this.setState({ isLoading: state });
  };

  updateHasDonated = (state) => {
    this.setState({ hasDonated: state });
  };

  updateAmount = (amount) => {
    this.setState({ amount: amount });
  };

  togglePayFees = (e) => {
    this.setState({ payFees: !this.state.payFees });
  };

  componentDidMount() {
    this.setState({ currentURL: window.location.href });

    this.fetchData();
  }


  // Fetch transaction data from the API
  fetchReplacments = async () => {
    try {
      this.setState({ isLoading: true });
      // Make API call to fetch transaction data
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/late-attendees`
      );

      return response.data;

    } catch (error) {
      // Handle errors
      this.setState({ error: error.message, isLoading: false });
    }
  };

  // Fetch transaction data from the API
  fetchData = async () => {
    const replacements = await this.fetchReplacments();

    try {
      this.setState({ isLoading: true });
      // Make API call to fetch transaction data
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/attendees`
      );

      const data = response.data;
      console.log(data);
      
    // Filter out attendees based on original_attendee_id from replacements
    const filteredData = data.filter(attendee => {
      return !replacements.some(replacement => replacement.original_attendee_id === attendee.attendee_id) &&
             attendee.isRegistrationComplete === 1 && attendee.attendee_id !== 328;
    });


      // Update state with fetched data
      this.setState({ data: filteredData, isLoading: false }, () => {
        console.log(this.state.attendees);
      });
    } catch (error) {
      // Handle errors
      this.setState({ error: error.message, isLoading: false });
    }
  };

  updateFirstName = (firstName) => {
    this.setState({ firstName: firstName });
  };

  updateTicketOption = (value, identifier) => {
    this.setState({ [identifier]: value });
  };

  updateTicketQuantity = (e) => {
    e.preventDefault();
    this.setState({ ticketQty: e.target.value });
  };

  updateRegistrationURL = (value) => {
    this.setState({ registrationURL: value });
  };

  render() {
    return (
      <>
        <BlueSection />
        <div className="donation-area bg-gray default-padding">
          {/* Fixed BG */}
          <Navbar />
          <div
            className={
              this.state.currentURL.includes("monthly")
                ? "fixed-bg monthly"
                : "fixed-bg"
            }
            id="bg-wrap"
          >
            <BackgroundSVG />
          </div>
          {/* End Fixed BG */}
          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-6 info"></div>
              <div className="col-lg-6 info text-light donation-form">
                <RegistrationForm
                  updateIsLoading={this.updateIsLoading}
                  attendees={this.state.data}
                  fetchData={this.fetchData}
                  toast={toast}
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.isLoading && <Spinner />}
        <ToastContainer />
        <Footer />
      </>
    );
  }
}

export default RegistrationComponent;
