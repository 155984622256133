import React from "react";
import Select from "react-select"; // Import Select from react-select
import {
  SEARCH_DROPDOWN_STYLE,
  DROPDOWN_STYLE,
  countryOptions,
  provinceOptions,
  stateOptions,
} from "../constants"; // Import DROPDOWN_STYLE
import "../../assets/css/AttendeeForm.css";

class SingleAttendeeForm extends React.Component {
  constructor(props) {
    super(props);
    console.log(props.attendees);
    this.state = {
      formData: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        provinceLabel: "Province",
        provinceOptions: provinceOptions,
        zip: "",
        zipLabel: "Postal Code",
        selectedAttendee: null,
        paddleNumber: null
      },
      errorEmail: "",
      errorPhoneNumber: "",
      pageTitle: "", // Add pageTitle state to hold the page title
    };

    this.state.formData.selectedProvince = provinceOptions[0];

    this.state.formData.selectedCountry = countryOptions[0];
  }

  componentDidMount() {
    // Parse name from URL and set the page title accordingly
    const pathname = window.location.pathname;
    const name = pathname.split("/").pop(); // Extract the name from the URL
    const capitalizedFirstName = name.charAt(0).toUpperCase() + name.slice(1); // Capitalize the first letter of the name
    this.setState({ pageTitle: `${capitalizedFirstName}'s Transfer Ticket Form` });
  }
  


  handleInputChange = (event) => {
    const { id, value } = event.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [id]: value,
      },
    }));
  };

  handleCountryChange = (selectedOption, formData, setFormData) => {
    let newLabel = "";
    let options = [];
    let newZipLabel = "";

    if (selectedOption.value === "USA") {
      newLabel = "State";
      options = stateOptions;
      newZipLabel = "Zip Code";
    } else if (selectedOption.value === "CAN") {
      newLabel = "Province";
      options = provinceOptions;
      newZipLabel = "Postal Code";
    }

    const updatedState = {
      selectedCountry: selectedOption,
    };

    if (newLabel !== formData.provinceLabel) {
      updatedState.provinceLabel = newLabel;
      updatedState.provinceOptions = options;
      updatedState.selectedProvince = options[0];
      updatedState.zipLabel = newZipLabel;
    }

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        ...updatedState,
      },
    }));
  };

  handleProvinceChange = (selectedOption) => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        selectedProvince: selectedOption,
      },
    }));
  };

  getDuplicateInfo = async (email, phoneNumber) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/check-duplicates`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, phoneNumber }),
      }
    );
    const data = await response.json();

    return data;
  };

  checkPhoneNumberForDuplicate = (phoneNumber, data) => {
    if (!phoneNumber) {
      return false;
    }

    return data.duplicatePhoneNumber;
  };

  checkEmailForDuplicate = (email, data) => {

    return data.duplicateEmail;
  };

  checkForDuplicates = async (email, phoneNumber) => {
    const data = await this.getDuplicateInfo(email, phoneNumber);

    const phoneNumberResult = this.checkPhoneNumberForDuplicate(
      phoneNumber,
      data
    );
    const emailResult = this.checkEmailForDuplicate(email, data);

    if (emailResult) {
      this.setState({
        errorEmail:
          "Need a unique email. Someone else has already been registered with this email.",
      });
    } else {
      this.setState({ errorEmail: "" });
    }

    if (phoneNumberResult) {
      this.setState({
        errorPhoneNumber:
          "Need a unique phone number. Someone else has already been registered with this phone number.",
      });
    } else {
      this.setState({ errorPhoneNumber: "" });
    }

    return emailResult || phoneNumberResult;
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.props.updateIsLoading(true);
    const { formData } = this.state;

    console.log(formData.selectedAttendee);

    // Check if any required field is empty
    const requiredFields = {
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email",
      phoneNumber: "Phone Number",
      addressLine1: "Address Line 1",
      city: "City",
      zip: "Zip",
    };
    const emptyFields = Object.entries(requiredFields)
      .filter(([field, label]) => !formData[field])
      .map(([field, label]) => label);

    if (emptyFields.length > 0) {
      this.props.toast.error(
        `Please fill in all required fields: ${emptyFields.join(", ")}`
      );
      this.props.updateIsLoading(false);
      return;
    }
    const duplicateError = await this.checkForDuplicates(
      formData.email,
      formData.phoneNumber
    );
    if (duplicateError) {
      const emailSection = document.getElementById("email-section");
      emailSection.scrollIntoView({ behavior: "smooth", block: "start" });
      this.props.updateIsLoading(false);
      return;
    }

    try {
      // Create a copy of formData
      const formDataCopy = { ...formData };

      // Add attendee_id to formDataCopy
      formDataCopy.selectedCountry = formDataCopy.selectedCountry.label;
      formDataCopy.selectedProvince = formDataCopy.selectedProvince.label;
      formDataCopy["previous_attendee_id"] = formDataCopy.selectedAttendee.value;

      // Assuming you have an API endpoint to update attendee details
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/late-attendees`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formDataCopy),
        }
      );

      if (response.ok) {
        this.props.toast.success("Ticket Transferred!");
        this.resetForm();
        
      } else {
        // Attendee details update failed
        console.error("Failed to update attendee details");
        // Optionally, handle error state or show a message to the user
      }
    } catch (error) {
      console.error("Error updating attendee details:", error);
      // Optionally, handle error state or show a message to the user
    }

    this.resetForm();

    this.props.updateIsLoading(false);
  };

  resetForm = () => {
    this.setState({
      formData: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        selectedProvince: provinceOptions[0],
        selectedCountry: countryOptions[0],
        provinceLabel: "Province",
        provinceOptions: provinceOptions,
        zip: "",
        zipLabel: "Postal Code",
        dietary: "",
        selectedAttendee: "",
        paddleNumber: null
      },
    });
  };

  handleAttendeeChange = (selectedOption) => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        selectedAttendee: selectedOption,
      },
    }));
  };

  getAttendeeOptions = () => {
    return this.props.attendees.map((attendee) => ({
      value: attendee.attendee_id, // Use "attendee_id" instead of "id"
      label: `${attendee.attendee_firstname} ${attendee.attendee_lastname}`, // Update property names
    }));
  };

  render() {
    const { formData } = this.state;

    return (
      <>
        <div className="col-md-12">
          <h4 style={{ color: "#2dccd3" }}>{this.state.pageTitle ? this.state.pageTitle: "Transfer Ticket Form"}</h4>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="attendee">
              Original Ticket Holder <span className="required">*</span>
            </label>
            <Select
              id="attendee"
              value={formData.selectedAttendee}
              onChange={this.handleAttendeeChange}
              options={this.getAttendeeOptions()}
              styles={SEARCH_DROPDOWN_STYLE}
              placeholder="Select transferee"
              required={true}
            />
          </div>
        </div>

        <div
          className="col-md-12"
          style={{
            textAlign: "center",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: "10px",
              paddingRight: "10px",
              color: "#54585a",
              backgroundColor: "#efefef",
            }}
          >
            <hr style={{ borderTop: "1px solid #54585a", flex: "1" }} />
            <span
              style={{
                fontSize: "1.25rem",
                marginRight: "10px",
                marginLeft: "10px",
              }}
            >
              New Ticket Holder Details
            </span>
            <hr style={{ borderTop: "1px solid #54585a", flex: "1" }} />
          </div>
        </div>

        <div className="col-lg-6">
      <div className="form-group">
        <label htmlFor="paddleNumber">
          Paddle Number <span className="required">*</span>
        </label>
        <input
          type="number"
          className="form-control"
          id="paddleNumber"
          placeholder="Paddle Number"
          value={formData.paddleNumber}
          onChange={this.handleInputChange}
          min={128}
          required={true}
        />
      </div>
    </div>
    
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="firstName">
              First Name <span className="required">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="firstName"
              placeholder="First Name"
              value={formData.firstName}
              onChange={this.handleInputChange}
              required={true}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="lastName">
              Last Name <span className="required">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={this.handleInputChange}
              required={true}
            />
          </div>
        </div>

        <div className="col-lg-6" id="email-section">
          <div className="form-group">
            <label htmlFor="email">
              Email <span className="required">*</span>
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Email"
              value={formData.email}
              onChange={this.handleInputChange}
              required={true}
            />
            {this.state.errorEmail && (
              <p className="error-message">{this.state.errorEmail}</p>
            )}
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="phoneNumber">
              Phone Number <span className="required">*</span>
            </label>
            <input
              type="tel"
              className="form-control"
              id="phoneNumber"
              placeholder="Phone Number"
              value={formData.phoneNumber}
              onChange={this.handleInputChange}
              required={true}
            />
            {this.state.errorPhoneNumber && (
              <p className="error-message">{this.state.errorPhoneNumber}</p>
            )}
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="addressLine1">
              Address <span className="required">*</span>
            </label>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                id="addressLine1"
                placeholder="Address Line 1"
                value={formData.addressLine1}
                onChange={this.handleInputChange}
                required={true}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              id="addressLine2"
              placeholder="Address Line 2"
              value={formData.addressLine2}
              onChange={this.handleInputChange}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="city">
              City <span className="required">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="city"
              placeholder="City"
              value={formData.city}
              onChange={this.handleInputChange}
              required={true}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="province">
              {formData.provinceLabel} <span className="required">*</span>
            </label>
            <Select
              id="province"
              defaultValue={formData.selectedProvince}
              value={formData.selectedProvince}
              className="form-control"
              options={formData.provinceOptions}
              styles={DROPDOWN_STYLE}
              onChange={this.handleProvinceChange}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="zip">
              {formData.zipLabel} <span className="required">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="zip"
              placeholder={"Your " + formData.zipLabel}
              value={formData.zip}
              onChange={this.handleInputChange}
              required={true}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="country">
              Country <span className="required">*</span>
            </label>
            <Select
              id="country"
              defaultValue={formData.selectedCountry}
              value={formData.selectedCountry}
              className="form-control"
              options={countryOptions}
              styles={DROPDOWN_STYLE}
              onChange={this.handleCountryChange}
            />
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="dietary">
              Do they have any dietary restrictions?
            </label>
            <input
              type="text"
              className="form-control"
              id="dietary"
              placeholder="Dietary Restrictions"
              value={formData.dietary}
              onChange={this.handleInputChange}
            />
          </div>
        </div>



        <button
          className="btn btn-theme effect btn-md"
          type="submit"
          onClick={this.handleSubmit}
        >
          Register
        </button>
      </>
    );
  }
}

export default SingleAttendeeForm;