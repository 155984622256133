import React from "react";

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: ''
    };
  }

  handlePhoneNumberChange = (event) => {
    const inputValue = event.target.value;

    if(/[^0-9()\- ]/.test(inputValue)) {
      // If it contains such characters, do not proceed further
      console.log("Input contains invalid characters, ignoring.");
      return;
  }

    let new_phoneNumber = inputValue.replace(/\D/g, '').slice(0, 10); // Remove non-digit characters and limit to 10 digits
    let old_phoneNumber = this.state.phoneNumber.replace(/\D/g, '').slice(0, 10); // Remove non-digit characters and limit to 10 digits

    if (this.state.phoneNumber.length > inputValue.length) {
      // handle delete case
      console.log("Delete case!")
      const updatedNumber = this.getDeletedPhoneNumber(old_phoneNumber);
      this.setState({phoneNumber: this.formatPhoneNumber(updatedNumber)});
      event.target.value = updatedNumber;
      this.props.handleInputChange(event);
    } else{
      console.log("NOT Delete case!")
      console.log("New: " + inputValue);
      console.log("Old: " + this.state.phoneNumber);
      // handle add new digit case
      this.setState({phoneNumber: inputValue});
      event.target.value = new_phoneNumber;
      this.props.handleInputChange(event);
    }
  }

  getDeletedPhoneNumber = (old_phoneNumber) => {
    return old_phoneNumber.slice(0, -1); // Remove the last digit
  }

  formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber.length === 0) {
      return "";
    }
    const areaCode = phoneNumber.slice(0, 3);
    const firstPart = phoneNumber.slice(3, 6);
    const secondPart = phoneNumber.slice(6, 10);
    return `(${areaCode}) ${firstPart}-${secondPart}`;
  }

  render() {
    const { formData, handleInputChange } = this.props; // Destructure props



    return (
      <>
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="email">
              Email <span className="required">*</span>
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Your Email"
              value={formData.email}
              onChange={handleInputChange}
              required={true}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="phoneNumber">
              Phone Number <span className="required">*</span>
            </label>
            <input
              type="tel"
              className="form-control"
              id="phoneNumber"
              placeholder="Your Phone Number"
              value={this.formatPhoneNumber(formData.phoneNumber)}
              onChange={this.handlePhoneNumberChange}
              required={true}
            />
          </div>
        </div>
      </>
    );
  }
}

export default ContactForm;