import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';

import { Route, Routes } from "react-router-dom";
import DonationComponent from './components/DonationComponent'
import CampaignComponent from './components/CampaignComponent';
import CobsCampaignComponent from './components/CobsCampaignComponent';
import BookCampaignComponent from './components/BookCampaignComponent';
import BuyTicketsComponent from './components/BuyTicketsComponent';
import AuthorizationWrapper from './components/AuthorizationComponent';
import PlaceholderTickets from "./components/PlaceholderTickets";
import SarahAndCamBuyTicketsComponent from './components/SarahAndCamBuyTicketsComponent';
import BigSistersBuyTicketsComponent from './components/BigSistersBuyTicketsComponent';
import RegistrationComponent from './components/Admin/RegistrationComponent';
import SameDayRegistrationComponent from './components/SameDayRegistration/RegistrationComponent';

function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<BuyTicketsComponent />} />
        <Route path="/gj24" element={<BuyTicketsComponent />} />
        <Route path="/gj2024" element={<BuyTicketsComponent />} />
        <Route path="/sarahandcam" element={<SarahAndCamBuyTicketsComponent />} />
        <Route path="/bigsisters" element={<BigSistersBuyTicketsComponent />} />
        <Route path="/mjibril" element={<BookCampaignComponent />} />
        <Route path="/cobs_panorama" element={<CobsCampaignComponent />} />
        <Route path="/preview/donate" element={<DonationComponent />} />
        <Route path="/preview/monthly" element={<DonationComponent />} />
        <Route path="/preview/campaign" element={<CampaignComponent />} />
        <Route path="/admin/nathania" element={<RegistrationComponent />} />
        <Route path="/admin/sasha" element={<RegistrationComponent />} />
        <Route path="/admin/marni" element={<RegistrationComponent />} />
        <Route path="/register-now" element={<SameDayRegistrationComponent />} />
        <Route path="/register/:url_query" element={<AuthorizationWrapper />} />
      </Routes>
    </div>
  );
}

export default App;
