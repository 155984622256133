import React, { Component } from "react";
import "../assets/css/DonationComponent.css";
import "../assets/css/responsive.css";
import StripeContainer from "./StripeContainer";
import Spinner from "../Spinner";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "./Navbar"
import Footer from "./Footer";
import ThankYouPage from "./ThankYouPage"
import BlueSection from "./BlueSection"
import { ReactComponent as BackgroundSVG } from "../assets/img/background.svg";

class CampaignComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      currentURL: "",
      hasDonated: false,
      amount: 100,
      payFees: false,
      firstName: ""
    };
  }

  updateIsLoading = (state) => {
    this.setState({ isLoading: state });
  };

  updateHasDonated = (state) => {
    this.setState({ hasDonated: state });
  };


  updateAmount = (amount) => {
    this.setState({ amount: amount });
  };

  togglePayFees = (e) => {
    this.setState({ payFees: !this.state.payFees });
  };

  componentDidMount() {
    this.setState({ currentURL: window.location.href });
  }

  updateFirstName = (firstName) => {
    this.setState({firstName: firstName})
  }

  render() {
    return (
      <>
      <BlueSection />
        <div className="donation-area bg-gray default-padding">
          {/* Fixed BG */}
          <Navbar />
          <div
            className={
              this.state.currentURL.includes("monthly")
                ? "fixed-bg monthly"
                : "fixed-bg"
            }
            id="bg-wrap"
          ><BackgroundSVG /></div>
          {/* End Fixed BG */}
          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-6 info">
              <form className="title" style={{backgroundColor: "#2dccd3"}}>
              <h1
                  class="area-title"
                >
                  Give the Gift of Mentorship This Holiday
                </h1>
              </form>

                <form className="mt-4">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-lg-6">
                        <input
                          style={{ width: "100%" }}
                          type="text"
                          className="form-control"
                          id="donation"
                          placeholder="Your Donation"
                          value=""
                        />
                      </div>
                      <div className="col-lg-6 text-right">
                        <button
                          style={{
                            width: "100%",
                            height: "50px",
                            backgroundColor: "red",
                          }}
                          className="btn btn-theme effect btn-md"
                          type="submit"
                        >
                          Calculate Tax Credit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>

                <form className="mt-4">
                  <h2 class="area-title">Help Support a Little Like Jen</h2>
                  <div className="col-lg-12">
                    <img
                      src="/example.jpg"
                      alt="Image description"
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        marginBottom: "30px",
                      }}
                    />
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Gravida rutrum quisque non tellus orci ac. Porta
                      nibh venenatis cras sed felis eget. In massa tempor nec
                      feugiat nisl pretium fusce id.
                    </p>
                    <p>
                      Aenean pharetra magna ac placerat vestibulum lectus.
                      Pretium lectus quam id leo in vitae. Odio facilisis mauris
                      sit amet massa vitae tortor condimentum lacinia. Amet
                      purus gravida quis blandit. Est sit amet facilisis magna
                      etiam tempor. Tellus in hac habitasse platea dictumst
                      vestibulum. Erat nam at lectus urna duis. Fermentum dui
                      faucibus in ornare. Massa id neque aliquam vestibulum
                      morbi. Nibh tellus molestie nunc non blandit massa enim.
                      Amet aliquam id diam maecenas ultricies mi eget. Tempus
                      egestas sed sed risus. Rhoncus mattis rhoncus urna neque.
                      Odio pellentesque diam volutpat commodo.
                    </p>
                  </div>
                </form>
              </div>
              <div className="col-lg-6 info text-light donation-form">
                {this.state.hasDonated ? <ThankYouPage amount={this.state.amount} 
                updateHasDonated={this.updateHasDonated} firstName={this.state.firstName}  /> : (
                  <StripeContainer updateIsLoading={this.updateIsLoading} 
                  updateHasDonated={this.updateHasDonated} 
                  amount={this.state.amount}  
                  updateAmount={this.updateAmount}
                  payFees={this.state.payFees}
                  togglePayFees={this.togglePayFees}
                  updateFirstName={this.updateFirstName}
                  formType="Campaign"
                  />
                )}
                
              </div>
            </div>
          </div>
        </div>
        {this.state.isLoading && <Spinner />}
        <ToastContainer />
        <Footer />
      </>
    );
  }
}

export default CampaignComponent;