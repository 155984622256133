import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { initialState, monthlyInitialState } from "./constants";
import { useLocation } from "react-router-dom";
import AttendeeForm from "./AttendeeForm";
import SingleAttendeeForm from "./SingleAttendeeForm";

const RegistrationForm = ({ updateIsLoading, attendees, fetchData }) => {
  const location = useLocation();
  const currentURL = location.pathname;
  const isMonthly = currentURL.includes("/monthly");

  const [formData, setFormData] = useState(
    isMonthly ? monthlyInitialState : initialState
  );

  const [activeAttendeeForm, setActiveAttendeeForm] = useState(-1);
  const titleRef = useRef(null);

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    updateIsLoading(true);
  };

  const handleRegister = (e, index) => {
    e.preventDefault();

    setActiveAttendeeForm(index);
  };

  const areAllAttendeesRegistered = () => {
    return attendees.every((attendee) => attendee.email);
  };

  return (
    <>
      <div className="form-box">
        <form onSubmit={handleSubmit}  style={{ minHeight: '60vh' }}>
          <h3 style={{ color: "#2dccd3" }} ref={titleRef}>
            { attendees.length === 1 ? 
            <>
            {attendees[0].isRegistrationComplete ? "See you at the Event!" : ""}
            </>
            : <>
              {areAllAttendeesRegistered()
              ? "See Your Event Attendees Below"
              : "Register Your GrapeJuice Attendees!"}
            </> }

          </h3>
          {!areAllAttendeesRegistered() && (
            <p style={{ color: "#232323" }}>
              For each attendee, please provide registration information.
            </p>
          )}

          <div className="row  text-light">
            {attendees.map((attendee, index) =>
              attendee.isRegistrationComplete ? (
                <h5 key={index} style={{ color: "#fcb61c" }}>
                  {attendee.attendee_firstname} {attendee.attendee_lastname}{" "}
                  registered!
                  {attendee.isPrimary ? (
                    <span style={{ color: "#ababab" }}> [Primary]</span>
                  ) : (
                    ""
                  )}
                </h5>
              ) : activeAttendeeForm === index || attendees.length === 1 ? (
                <>
                {attendees.length === 1 ?  (<SingleAttendeeForm
                  key={index}
                  index={index}
                  setActiveAttendeeForm={setActiveAttendeeForm}
                  attendee={attendee}
                  toast={toast}
                  fetchData={fetchData}
                  updateIsLoading={updateIsLoading}
                />): (
                <AttendeeForm
                key={index}
                index={index}
                setActiveAttendeeForm={setActiveAttendeeForm}
                attendee={attendee}
                toast={toast}
                fetchData={fetchData}
                updateIsLoading={updateIsLoading}
              />)}
                </>

              ) : (
                <>
                  {activeAttendeeForm === -1 && (
                    <div className="col-md-12" key={index}>
                      <div className="form-group">
                        {!attendee.email || (attendee.isPrimary && attendee.email && !attendee.isRegistrationComplete) ? (
                          <button
                            className="btn btn-theme effect btn-md"
                            type="submit"
                            onClick={(e) => handleRegister(e, index)}
                            style={{ width: "100%" }}
                          >
                            Register Attendee {index + 1}
                          </button>
                        ) : (
                          <>
                            <h5 key={index} style={{ color: "#fcb61c" }}>
                              {attendee.attendee_firstname
                                ? attendee.attendee_firstname
                                : `Attendee ${index + 1}`}
                              's Registration is pending!
                            </h5>
                            <p style={{ color: "black" }}>
                              Your attendee will get an email to finish
                              registration, and then their tickets will be sent
                              directly to them.
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )
            )}

          </div>
        </form>
      </div>
    </>
  );
};

export default RegistrationForm;