import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DonationOptions from "./DonationOptions";
import ApplePayButton from "./ApplePayButton";
import GooglePayButton from "./GooglePayButton";
import {
  stateOptions,
  provinceOptions,
  initialState,
  CARD_ELEMENT_OPTIONS,
  monthlyInitialState,
} from "./constants";
import AddressForm from "./AddressForm";
import ContactForm from "./ContactForm";
import NameForm from "./NameForm";
import BillingForm from "./BillingForm";
import AmountForm from "./AmountForm";
import { useLocation } from "react-router-dom";
import SingleChoiceDropdown from "./SingleChoiceDropdown";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import createIntentPayment from "../services/campaigns_payment_intent";
import createOtherIntentPayment from "../services/campaigns_other_pay_payment_intent";
import { calculateTotalAmount } from '../services/paymentUtils';

const CampaignForm = ({
  updateIsLoading,
  updateHasDonated,
  amount,
  updateAmount,
  togglePayFees,
  payFees,
  updateFirstName
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const currentURL = location.pathname;
  const isMonthly = currentURL.includes("/monthly");

  const [formData, setFormData] = useState(
    isMonthly ? monthlyInitialState : initialState
  );

  const [paymentRequest, setPaymentRequest] = useState(null);
  const [applePay, setApplePay] = useState(false);
  const [googlePay, setGooglePay] = useState(false);

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }
    const pr = stripe.paymentRequest({
      country: "CA",
      currency: "cad",
      requestPayerEmail: true,
      requestPayerName: true,
      total: {
        label: "Donation to Big Sisters",
        amount: 1000,
      },
    });

    pr.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(pr);
        setApplePay(result.applePay);
        setGooglePay(result.googlePay);
      }
    });

    pr.on("paymentmethod", async (e) => {
      await createOtherIntentPayment(stripe, updateFirstName, amount, payFees, e, toast, updateHasDonated, formData);
    });
  }, [stripe, elements, amount, payFees, updateFirstName]);

  const tooltip = (
    <Tooltip id="tooltip">
      By covering the transaction fee, Big Sisters of BC Lower Mainland will keep 100% of your donation.
    </Tooltip>
  );

  const updatePaymentRequestInstance = async (event) => {
    if (!amount) {
      updateAmount(1000);
    }

    paymentRequest.update({
      country: "CA",
      currency: "cad",
      total: {
        label: "Donation to Big Sisters",
        amount: calculateTotalAmount(amount, payFees, formData) * 100,
      },
    });
  };

  const toggleValue = (e, label) => {
    console.log(e);
    console.log(label);

    setFormData({
      ...formData,
      [label]: !formData[label],
    });
  };

  const handleInputChange = (event) => {
    if (event.target.id === "amount") {
      updateAmount(event.target.value);
    } else {
      setFormData({
        ...formData,
        [event.target.id]: event.target.value,
      });
    }
  };


  const handleChoiceUpdate = (option, label) => {
      setFormData({
        ...formData,
        [label]: option.value,
      });

  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    updateIsLoading(true);

    if (!stripe || !elements) {
      toast.error("Something went wrong. Please refresh the page.");
      updateIsLoading(false);
      return;
    }
    const cardElement = elements.getElement(CardElement);

    await createIntentPayment(stripe, cardElement, updateFirstName, amount, payFees, formData, updateHasDonated, toast, setFormData, updateIsLoading);
  };

  const handleCountryChange = (selectedOption) => {
    let newLabel = "";
    let options = [];
    let newZipLabel = "";

    if (selectedOption.value === "USA") {
      newLabel = "State";
      options = stateOptions;
      newZipLabel = "Zip Code";
    } else if (selectedOption.value === "CAN") {
      newLabel = "Province";
      options = provinceOptions;
      newZipLabel = "Postal Code";
    }

    const updatedState = {
      selectedCountry: selectedOption,
    };

    if (newLabel !== formData.provinceLabel) {
      updatedState.provinceLabel = newLabel;
      updatedState.provinceOptions = options;
      updatedState.selectedProvince = options[0];
      updatedState.zipLabel = newZipLabel;
    }

    setFormData({ ...formData, ...updatedState });
  };

  const handleBillingProvinceChange = (selectedOption) => {
    setFormData({ ...formData, billingSelectedProvince: selectedOption });
  };

  const handleBillingCountryChange = (selectedOption) => {
    let newLabel = "";
    let options = [];
    let newZipLabel = "";

    if (selectedOption.value === "USA") {
      newLabel = "State";
      options = stateOptions;
      newZipLabel = "Zip Code";
    } else if (selectedOption.value === "CAN") {
      newLabel = "Province";
      options = provinceOptions;
      newZipLabel = "Postal Code";
    }

    const updatedState = {
      billingSelectedCountry: selectedOption,
    };

    if (newLabel !== formData.billingProvinceLabel) {
      updatedState.billingProvinceLabel = newLabel;
      updatedState.billingProvinceOptions = options;
      updatedState.billingSelectedProvince = options[0];
      updatedState.billingZipLabel = newZipLabel;
    }

    setFormData({ ...formData, ...updatedState });
  };

  const handleProvinceChange = (selectedOption) => {
    setFormData({ ...formData, selectedProvince: selectedOption });
  };

  const handleAmountClick = (amount) => {
    updateAmount(amount);

    const amountInput = document.getElementById("amount");
    if (amountInput) {
      amountInput.value = amount.toString();
    }
  };

  const handleRealButtonClick = () => {
    updatePaymentRequestInstance();
    paymentRequest.show();
  };

  const formatAsCurrency = (amount) => {
    // Assuming amount is a number
    return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }

  return (
    <>
      <div className="form-box">
        <form onSubmit={handleSubmit}>
        <h3 style={{ color: "#2dccd3" }}>
            Campaign!
          </h3>
          <p style={{ color: "#232323" }}>
            Make a donation to Big Sisters of BC Lower Mainland and help create
            life-changing opportunities for youth in our community. Give them
            the support they need now and in the future.
          </p>
          <DonationOptions
            handleAmountClick={handleAmountClick}
            amount={amount}
            handleCustomAmount={toggleValue}
          />
          <br />
          <div className="row  text-light">
            <AmountForm
              handleInputChange={handleInputChange}
              formData={formData}
              toggleValue={toggleValue}
              isMonthly={isMonthly}
              amount={amount}
            />

            <div className="col-md-12">
              <div className="form-group">
              <div className="d-flex flex-wrap align-items-center">
              <label style={{ display: "flex", alignItems: "center" }} className="coverFeeOption">
                  <input
                    type="checkbox"
                    onChange={togglePayFees}
                    checked={payFees}
                    style={{ accentColor: "#2dccd3" }} // Adjust marginRight as needed
                  />
                  &nbsp;&nbsp;I will generously cover the&nbsp;<span style={{fontWeight: "bold"}}>transaction fees</span>.&nbsp;
                  <OverlayTrigger placement="right" overlay={tooltip}>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      style={{ color: "#2dccd3" }}
                    />
                  </OverlayTrigger>
                </label>

              </div>

                
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
              {payFees && amount && amount > 0 && (<>
                  <p style={{"color": "black"}}>Your donation will come to {formatAsCurrency(calculateTotalAmount(amount, payFees, formData))}.</p>

                </>)}
              </div></div>

            {applePay && (
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="ApplePay">Donate by Apple Pay</label>
                  <div className="input-group">
                    <ApplePayButton handleClick={handleRealButtonClick} />
                  </div>
                </div>
              </div>
            )}

            {googlePay && (
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="GooglePay">Donate by Google Pay</label>
                  <div className="input-group">
                    <GooglePayButton handleClick={handleRealButtonClick} />
                  </div>
                </div>
              </div>
            )}

            <NameForm
              formData={formData}
              handleInputChange={handleInputChange}
              handleProvinceChange={handleProvinceChange}
              toggleValue={toggleValue}
              handleCountryChange={handleCountryChange}
              handleBillingProvinceChange={handleBillingProvinceChange}
              handleBillingCountryChange={handleBillingCountryChange}
              creditCardSectionTitle={applePay || googlePay ? "Or Credit Card Payment" : "Credit Card Payment"}
            />

            <ContactForm
              formData={formData}
              handleInputChange={handleInputChange}
              handleProvinceChange={handleProvinceChange}
              toggleValue={toggleValue}
              handleCountryChange={handleCountryChange}
              handleBillingProvinceChange={handleBillingProvinceChange}
              handleBillingCountryChange={handleBillingCountryChange}
            />

            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="cardNumber">
                  Donate by Credit Card <span className="required">*</span>
                </label>
                <div className="input-group">
                  <CardElement
                    className="form-control"
                    options={CARD_ELEMENT_OPTIONS}
                  />
                </div>
              </div>
            </div>

            <AddressForm
              formData={formData}
              handleInputChange={handleInputChange}
              handleProvinceChange={handleProvinceChange}
              toggleValue={toggleValue}
              handleCountryChange={handleCountryChange}
              handleBillingProvinceChange={handleBillingProvinceChange}
              handleBillingCountryChange={handleBillingCountryChange}
            />

            <BillingForm
              formData={formData}
              handleInputChange={handleInputChange}
              handleProvinceChange={handleProvinceChange}
              toggleValue={toggleValue}
              handleCountryChange={handleCountryChange}
              handleBillingProvinceChange={handleBillingProvinceChange}
              handleBillingCountryChange={handleBillingCountryChange}
            />

            <SingleChoiceDropdown
              value={formData.anonymous}
              toggleValue={(e) => toggleValue(e, "anonymous")}
              label="Make it anonymous?"
              identifier="anonymous"
              outerClass="col-md-12"
            />




            <button className="btn btn-theme effect btn-md" type="submit">
              Donate Now
            </button>

            <div className="question">
              <div className="icon">
                <i className="fas fa-phone"></i>
              </div>

              <div className="info">
                <h5 style={{ color: "rgb(45, 204, 211)" }}>
                  Have any questions about your donation?
                </h5>
                <span style={{ color: "#232323" }}>
                  Call Now: 1-604-873-4525
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default CampaignForm;
